import React, {useContext, useMemo} from 'react'
import {configName} from '../../helpers/constants'
import {OptionsContext} from '../../App.js'
import {getRendererOptions} from '../../helpers/functions.js'

export default function VerticalBars({size, wall, texture}) {

    const { config } = useContext( OptionsContext )
    const model = config[configName.model].value.optionsSelected[0]

    return useMemo(() => {
        const additionalOutside = getRendererOptions({ config, groupName: configName.construction, selected: true })
        const cassetteDesign = config[configName.construction2].value.optionsSelected[0]

        if (!(additionalOutside.id === 0 || cassetteDesign.id === 0)) return null

        const breakPointWidth = 1.7
        const railWidth = size.model.wallThickness
        const railVerPosZ = size.model.wallThickness/2 + .0001

        let arrayBars = []

        if (wall === 'front') {
            const posX = size.model.width / 2 - railWidth / 2

            if (model.name === 'venus') {
                if (size.model.width >= breakPointWidth) {
                    const posMiddle = size.door.width / 2 + railWidth / 2
                    arrayBars = [posX, posMiddle, - posMiddle, - posX]
                } else {
                    const center = posX - size.door.width - railWidth
                    const posCenter = size.glassElem.side === 'R' ? center : - center
                    arrayBars = [posX, posCenter, - posX]
                }
            }

            if (model.name === 'kristall') {
                arrayBars = [posX, - posX]
            }

            if (model.name === 'morena') {
                if (size.model.width >= breakPointWidth) {
                    const middle = size.door.width / 2 + railWidth / 2
                    const posMiddle = size.glassElem.side === 'R' ? middle : - middle
                    arrayBars = size.glassElem.side === 'R' ? [posX, posMiddle] : [posMiddle, - posX]
                } else {
                    arrayBars = size.glassElem.side === 'R' ? [posX] : [- posX]
                }
            }

        } else {

            const width = size.model[ wall === 'back' ? 'width' : 'depth' ]
            const posX = width / 2 - railWidth / 2

            if (width > breakPointWidth) {

                const posMiddle = (width - railWidth) / 6
                arrayBars = [posX, posMiddle, - posMiddle, - posX]

                if (model.name === 'morena') {
                    if (wall === 'right' && size.glassElem.side === 'R') {
                        arrayBars = [posMiddle, - posMiddle, - posX]
                    }

                    if (wall === 'left' && size.glassElem.side === 'L') {
                        arrayBars = [posX, posMiddle, - posMiddle]
                    }
                }

            } else {

                arrayBars = [posX, 0, - posX]

                if (model.name === 'morena') {
                    if (wall === 'right' && size.glassElem.side === 'R') {
                        arrayBars = [0, - posX]
                    }

                    if (wall === 'left' && size.glassElem.side === 'L') {
                        arrayBars = [posX, 0]
                    }
                }

            }
        }

        const Bars = ({ name, position, firstOrLast }) => (
            <mesh name={name} position={position}>
                <planeGeometry args={[name === 'internal' && firstOrLast ? railWidth/2 : railWidth, size.model.height]} />
                <meshStandardMaterial
                    map={ name === 'internal' ? texture.in.bar.ver : texture.out.bar.ver}
                    side={ name === 'internal' ? 0 : 1 }
                />
            </mesh>
        )

        return arrayBars.map((x, i, array) => {
            const first = i === 0
            const last = i === array.length - 1
            const innerX = x + (first ? - size.model.wallThickness/1.33 : last ? + size.model.wallThickness/1.33 : 0)

            return (
                <group key={i}>
                    {additionalOutside.id === 0
                        ? <Bars name="external" position={[x, 0, -railVerPosZ]}/>
                        : null}
                    {cassetteDesign.id === 0
                        ? <Bars name="internal" position={[innerX, 0, railVerPosZ]} firstOrLast={first || last}/>
                        : null}
                </group>
            )
        })

    }, [size, texture, config[configName.construction2].value, config[configName.construction].value])
}
