/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.4.1 Knauf_Schwarz_black.glb 
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Knauf_Schwarz_black(props) {
  const { nodes, materials } = useGLTF('/assets/models/doorHandle/Knauf_Schwarz_black.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Cylinder.geometry} material={materials['Material.006']} position={[0, 0.061, -0.335]} rotation={[Math.PI / 2, 0, 0]} scale={[0.337, 0.553, 0.337]} />
      <mesh geometry={nodes.Sphere.geometry} material={materials['Material.006']} position={[0, 0, -1.347]} scale={0.986} />
      <mesh geometry={nodes.Circle.geometry} material={materials['Material.005']} position={[0, 0.066, 0.257]} rotation={[Math.PI / 2, 0, 0]} scale={0.326} />
    </group>
  )
}

useGLTF.preload('/assets/models/doorHandle/Knauf_Schwarz_black.glb')
