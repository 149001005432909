import {useContext, useMemo} from 'react'
import {OptionsContext} from '../../../App.js'

import Size from './Size.js'
import TooltipButton from '../tooltip/TooltipButton.js'
import {configName} from '../../../helpers/constants.js'
import {getRendererOptions} from '../../../helpers/functions.js'
import './size.css'

export default function Sizes({ configGroup }) {

    const { config, priceTable } = useContext( OptionsContext )

    const minMaxSizes = useMemo(() => {
        const model = config[configName.model].value.optionsSelected[0]
        const type = config[configName.typeWood].value.optionsSelected[0]
        const table = priceTable[ model.name ][ type.name === 'hz0' ? 'fichte' : type.name === 'hz1' ? 'zirbe' : 'rest' ]

        if ( !table ) return { width: { min: 1.3, max: 2.5 }, depth: { min: 1.3, max: 2.5 }}

        const getMeters = v => Number( v ) / 100

        return {
            width: {
                min: getMeters(table[0][1]),
                max: getMeters(table[0][table[0].length - 1]),
            },
            depth: {
                min: getMeters(table[1][0]),
                max: getMeters(table[table.length - 1][0]),
            }
        }

    }, [config[configName.model].value, config[configName.typeWood].value])

    return (
        <li className="options_panel__section size">
            <div className="options_panel__header">
                <h2 className="title">Sauna Außenmaße</h2>
                <TooltipButton item={ configGroup.value } />
            </div>
            <Size option={configGroup.value.options[0]} minMax={minMaxSizes.width}/>
            <Size option={configGroup.value.options[1]} minMax={minMaxSizes.depth}/>
        </li>
    )
}
