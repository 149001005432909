export const configName = {
    model: 'model',
    size: 'size',

    typeWood: 'type_wood',
    typeWood2: 'type_wood_in',
    construction: 'construction_out', // out cassette design
    construction2: 'construction_in',             // in cassette design

    roomWalls: 'raumwande',

    glassElem: 'glaselemente',
    glassType: 'glastyp',

    fittings: 'turbeschlage',
    doorHandles: 'turgriffe',

    lamp: 'beleuchtung',
    typeWoodBench: 'type_wood_bench',
    benchOption: 'bench_option',
    bankaufstellung: 'bankaufstellung',
    benchLayout: 'bench_layout',


    accesories: 'eimer',
    bankType: 'banktyp',
    frontOfTheBank: 'vorderseite_der_bank',

    oven: 'ofen',
    ovenFinnish: 'finnischer_ofen',
    ovenBio: 'bio_ofen',

    steering: 'steuerung',
    additionalModule: 'zusatzmodul',
    irEmitter: 'ir_strahler',
    bucket: 'eimer',
    thermometer: 'thermometer',
    other: 'andere',
}

export const additionalOptionName = {
    selectPage: 'Seite auswahlen',
    left: 'links',
    right: 'rechts'
}

export const modelHeight = 1.88

// Kassetten 0
// Platte 1
// Profilholz 2
// Stäbchen 3
export const depTexturesConstructions = [
    ['kassetten','platte','profilholz','stabchen'], //  0 Astige Fichte
    ['kassetten','platte','profilholz','stabchen'], //  1 Astige Zirbe
    ['platte','profilholz','stabchen'],             //  2 Erle
    ['platte','profilholz','stabchen'],             //  3 Espe
    ['platte'],                                     //  4 Thermo erle
    ['platte','profilholz'],                        //  5 Thermo espe
    ['platte'],       //  6 Eiche rissig
    ['platte'],       //  7 Eiche Altholz
    ['platte'],       //  8 Hemlock
    ['platte'],       //  9 Structure
    ['platte'],       // 10 Structure
    ['platte']        // 11 Structure
]

export const depConstructionsTextures = {}
depTexturesConstructions.forEach((ids, i) => {
    ids.forEach(id => {
        if (!depConstructionsTextures[id]) depConstructionsTextures[id] = []
        depConstructionsTextures[id].push(i)
    })
})