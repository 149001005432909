import React, {useContext, useEffect, useState, Suspense, useRef, useMemo} from 'react'
import {Canvas} from '@react-three/fiber'
import {OrbitControls, Loader} from '@react-three/drei'
import OptionsPanel from './components/options/optionsPanel/OptionsPanel.js'
import Tooltip from './components/options/tooltip/Tooltip.js'
import ResetOptions from './components/options/resetOptions/ResetOptions.js'
import Light from './components/light/Light.js'
import {OptionsContext} from './App.js'
import {getDefaultConfig} from './helpers/functions.js'
import {configName} from './helpers/constants.js'
import ShowOptionPanel from './components/options/showOptionPanel/ShowOptionPanel.js'
import './configurator.css'

import { Leva } from 'leva'
import Debug from './Debug.js'
import {SaunaModel} from './components/models/SaunaModel'
import {Icon} from './components/icon'

export default function Configurator({ options }) {

    const { config, tooltipRef, showOptionPanel, setTotalPrice, ref, priceTable, setDefaultConfig } = useContext( OptionsContext )

    /** set Config */
    options.forEach(option => {
        if ([configName.size, 'modelSize', 'size'].includes(option.name)) {
            option.width = option.options[0].value
            option.depth = option.options[1].value
            delete option.options[0].value
            delete option.options[1].value
        }

        const [value, setValue] = useState(option)
        config[option.name] = {value, setValue}
    })

    useEffect(() => {
        setDefaultConfig(getDefaultConfig(config))
    }, [priceTable])

    const initialStateHandler = active => {
        if (ref.screenSaver.current) {
            if (active) {
                ref.screenSaver.current.classList.add('show')
            } else {
                ref.screenSaver.current.classList.remove('show')
            }
        }
    }

    // console.log( 'Configurator' )
    return (
        <div id="configurator">
            {/*<Debug />*/}
            <Leva
                // collapsed
                // hidden
            />
            <div id="canvas_wrapper" className={ showOptionPanel ? '' : 'full_screen' } ref={ref.canvasWrapper}>
                <Canvas
                    camera={{ position: [ - 5, 1, 3 ] }}
                    pixelratio={[1, 2]}
                    shadows
                    gl={{ preserveDrawingBuffer: true }}
                >
                    <Suspense fallback={null}>
                        {/*<Stats />*/}

                        <color attach="background" args={['#f3f3f3']} />

                        <Light />

                        <SaunaModel />

                        <OrbitControls
                            // minDistance={2}
                            // maxDistance={4}
                            // autoRotate
                        />

                        {/*<gridHelper />*/}
                        {/*<axesHelper scale={5} />*/}
                    </Suspense>
                </Canvas>

                {/* Загрузочна заставка під час завантаження моделі, наприклад пічки, запобігти миготіння загальної сцени */}
                <div className="screen_saver" ref={ ref.screenSaver } >
                    <Icon icon="spinner" className="spinner" />
                    <Loader
                        containerStyles={{background: 'transparent'}} // Flex layout styles
                        // innerStyles={...inner} // Inner container styles
                        // barStyles={...bar} // Loading-bar styles
                        dataStyles={{fontSize: '12px'}} // Text styles
                        dataInterpolation={n => `Laden ${n.toFixed(0)}%`} // Text
                        initialState={initialStateHandler} // Initial black out state
                    />
                </div>
            </div>

            <ResetOptions />
            <ShowOptionPanel />

            <OptionsPanel />

            <div className="option_small_tooltip hide" ref={ tooltipRef }><Tooltip /></div>
            <div className="option_3d_info hide" ref={ ref.info3d }>
                <div className="text">Test info 3D info Test info 3D info Test info 3D info</div>
            </div>
        </div>
    )
}