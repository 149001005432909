import {useContext} from 'react'
import {OptionsContext} from '../../../App.js'
import OptionItem from './OptionItem.js'
import TooltipButton from '../tooltip/TooltipButton.js'
import AdditionalOptions from '../additionalOptions/AdditionalOptions.js'
import {getRendererOptions} from '../../../helpers/functions.js'
import {configName} from '../../../helpers/constants.js'

export default function OptionGroup({ configGroup }) {
    const { config } = useContext(OptionsContext)
    const { value: itemValue } = configGroup

    const renderOptions = getRendererOptions({config, groupName: itemValue.name })

    // Отримати вибрану опцію
    let currentOption = renderOptions
        .find(renderOption => itemValue.optionsSelected
            .find(item => item?.name === renderOption?.name && item?.id === renderOption?.id))

    const renderOptionsList = renderOptions.map(option => (
        <OptionItem
            key={option.id}
            currentOption={currentOption}
            selectedOption={option}
            configGroup={configGroup}
        />
    ))

    return <>
        {
            renderOptionsList.length > 0 && (
                <li className={`options_panel__section ${itemValue.name}`}>

                    { itemValue.name === configName.accesories
                        && <h2 className="options_panel__section_title">Zubehör</h2>}

                    <div className="options_panel__header">
                        <h3 className="title"><span>{itemValue.title}</span></h3>
                        <TooltipButton item={itemValue}/>
                    </div>

                    <div className="options_panel__checkboxes_wrapper">
                        <ul className={`options_panel__checkboxes option_group_${ itemValue.name }`}>
                            {renderOptionsList}
                        </ul>
                        {
                            currentOption?.additional_options &&
                            <AdditionalOptions option={currentOption} configGroup={configGroup} />
                        }
                    </div>
                </li>
            )
        }
    </>
}