import React, {useContext, useEffect, useMemo, useRef} from 'react'
import {Base, Geometry, Subtraction} from '@react-three/csg'
import {configName} from '../../helpers/constants'
import {OptionsContext} from '../../App.js'
import * as THREE from "three";
import {useTexture} from '@react-three/drei'

export default function Watermark({size, wall}) {

    const { config } = useContext( OptionsContext )
    const model = config[configName.model].value.optionsSelected[0]

    const watermarkRef = useRef()

    useEffect(() => {
        const roomWalls = config[configName.roomWalls].value.optionsSelected[0].id
        watermarkRef.current.visible = !(
            !roomWalls
            || roomWalls === 1 && wall === 'right'
            || roomWalls === 2 && wall === 'front'
            || roomWalls === 3 && wall === 'left'
            || roomWalls === 4 && (wall === 'left' || wall === 'right')
            || roomWalls !== 5 && wall === 'front'
        )
    }, [config[configName.roomWalls].value])

    const [texture1] = useTexture(['/assets/textures/text_1.jpg'])

    const watermark = useMemo(() => {
        texture1.rotation = THREE.MathUtils.degToRad(225)
        texture1.wrapS = texture1.wrapT = THREE.RepeatWrapping
        texture1.repeat.set( size.model.width/.6, size.model.height/.3 )
        texture1.needsUpdate = true

        return texture1

    }, [])

    return useMemo(() => {

        // FRONT WALL

        if (wall === 'front') {
            const geometry = new THREE.PlaneGeometry(size.model.width, size.model.height)
            const geometryDoorway = new THREE.PlaneGeometry(size.door.width, size.door.height)
            const posY = (size.model.height - size.door.height)/2
            const x = size.model.width/2 - size.door.width/2 - size.model.wallThickness
            const posX = size.model.width >= 1.7 ? 0 : size.glassElem.side === 'R' ? x : - x
            const positionDoorway = [posX, posY, 0]

            return (
                <mesh ref={watermarkRef} position-z={-.04102} rotation={[0, Math.PI, Math.PI]} visible={false}>
                    <Geometry useGroups>
                        <Base geometry={geometry}>
                            <meshStandardMaterial color={'#ffffff'} transparent opacity={.5} aoMap={watermark} rotation={[Math.PI, 0, 0]} side={2}/>
                        </Base>
                        <Subtraction geometry={geometryDoorway} position={positionDoorway}/>
                    </Geometry>
                </mesh>
            )

        } else {

            // ALL WALLS

            let width = size.model[ wall === 'back' ? 'width' : 'depth' ]
            let posX = 0

            if (model.name === 'morena') {

                if (size.glassElem.side === 'R' && wall === 'right') {
                    width = width - size.window.w2.width
                    posX = - (size.model.depth / 2 - width / 2)
                }

                if (size.glassElem.side === 'L' && wall === 'left') {
                    width = width - size.window.w2.width
                    posX = size.model.depth / 2 - width / 2
                }
            }

            return (
                <mesh ref={watermarkRef} position-z={-.04102} position-x={posX} rotation-x={Math.PI} visible={false}>
                    <planeGeometry args={[width, size.model.height]} />
                    <meshStandardMaterial color={'#ffffff'} transparent opacity={.4} aoMap={watermark} rotation={[Math.PI, 0, 0]}/>
                </mesh>
            )
        }

    }, [size])
}