import {useContext, useEffect, useRef, useState, useMemo} from 'react'
import { OptionsContext } from '../../../App.js'
import TooltipButton from '../tooltip/TooltipButton.js'
import {configName, depConstructionsTextures, depTexturesConstructions} from '../../../helpers/constants.js'
import './optionGroup.css'

export default function OptionItem({ currentOption, selectedOption, configGroup }) {

    const { config, priceTable } = useContext( OptionsContext )
    const { id, name, img, title, price, group } = selectedOption
    const { value: configGroupValue, setValue: setConfigGroupValue } = configGroup

    const [styleLabelIn, setStyleLabelIn] = useState({})

    const refImg = useRef()

    /** update height item content */
    useEffect(() => {
        function updateHeightLabel() {
            const heightImg = refImg.current.getBoundingClientRect().height
            setStyleLabelIn({
                minHeight: `calc(100% - ${heightImg + 'rem'})`,
                height: 'max-content',
            })
        }

        updateHeightLabel()

        window.addEventListener('resize', updateHeightLabel)
        return () => window.removeEventListener('resize', updateHeightLabel);
    }, [])

    function onOptionChange(event) {
        if (!(selectedOption._available && selectedOption.available)) return
        if (event.target.closest('.checkbox__tooltip_button')) return


        // TODO: Спробувати не використовувати функцію "setConfigGroupValue" а напряму змінити дані
        setConfigGroupValue(value => {

            // Select multiple options

            if ( configGroup.value.name === configName.lamp ) {

                if (selectedOption.id === 0) {
                    value.optionsSelected = [selectedOption]
                } else {

                    const existsIndex = value.optionsSelected.findIndex(o => o.id === selectedOption.id)

                    if (existsIndex < 0) {
                        const selGroup = selectedOption.name.split('-')[1]
                        const filterResult = value.optionsSelected.filter(o => {
                            const group = o.name.split('-')[1]
                            return (!group || group !== selGroup) && group !== 'not'
                        })
                        value.optionsSelected = [...filterResult, selectedOption]
                    } else {
                        value.optionsSelected.splice(existsIndex, 1)
                    }

                    if (!value.optionsSelected.length) {
                        value.optionsSelected.push( value.options[0] )
                    }
                }
                // console.log( value.optionsSelected.map(a => a.name.slice(-3) ) )
            } else {

                // Single choice

                const indexArr = value.optionsSelected
                    .findIndex(o => o.name === currentOption.name && o.id === currentOption.id)

                value.optionsSelected.splice(indexArr, 1)
                value.optionsSelected.push(selectedOption)
                // console.log( value.optionsSelected.map(a => a.name ) )
            }

            return {...value}
        })
    }

    /**
     *  Логіка з Текстурами та Конструкціями і їх залежності
     *
     *  Керування доступністю Текстур від вибору Конструкції (Зовні)
     *  Dependencies:
     *    Construction -> Type Wood (Out)
     */
    useMemo(() => {
        const name = config[configName.construction].value.optionsSelected[0].name
        config[configName.typeWood].value.options
            .forEach(option => option.available = depConstructionsTextures[name].includes(option.id))
    }, [config[configName.construction].value])

    /**
     *  Керування доступністю Конструкцій від вибору Текстури (Зовні)
     *  Dependencies:
     *    Type Wood -> Construction (Out)
     */
    useMemo(() => {
        const id = config[configName.typeWood].value.optionsSelected[0].id
        config[configName.construction].value.options
            .forEach(option => option.available = depTexturesConstructions[id].includes(option.name))
    }, [config[configName.typeWood].value])

    /**
     *  Керування доступністю Текстур від вибору Конструкції (Всередині)
     *  Dependencies:
     *    Construction -> Type Wood (In)
     */
    useMemo(() => {
        if (config[configName.model].value.optionsSelected[0].name === 'morena') {
            let name = config[configName.construction2].value.optionsSelected[0].name
            config[configName.typeWood2].value.options
                .forEach(option => option.available = depConstructionsTextures[name].includes(option.id))
        } else {
            config[configName.typeWood2].value.options.forEach(option => option.available = true)
            config[configName.typeWood2].value.optionsSelected = [config[configName.typeWood2].value.options[0]]
        }
    }, [config[configName.construction2].value])

    /**
     *  Керування доступністю Конструкцій від вибору Текстури (Всередині)
     *  Dependencies:
     *    Type Wood -> Construction (In)
     */
    useMemo(() => {
        let id = config[configName.typeWood2].value.optionsSelected[0].id
        config[configName.construction2].value.options
            .forEach(option => option.available = depTexturesConstructions[id].includes(option.name))
    }, [config[configName.typeWood2].value])

    /**
     *  Змінити вибір типу деревини при зміні моделі з Морени на іншу,
     *  якщо ID типу деревини був 2 або більше
     */
    useMemo(() => {
        if ( config[configName.typeWood].value.optionsSelected[0].id > 1 ) {
            config[configName.typeWood].value.optionsSelected[0] = config[configName.typeWood].value.options[0]
        }
    }, [config[configName.model].value])

    /**
     * Get css classes for the option element
     */
    function getClassNameCheckboxItem() {
        return `checkbox_item option_item_${currentOption.name} ${
            selectedOption._available && selectedOption.available ? 'checkbox_item--available' : ''
        } ${
            (configGroup.value.name === configName.lamp &&
                configGroupValue.optionsSelected.findIndex(o => o.name === name && o.id === id) >= 0)
            ||
            currentOption.name === name && currentOption.id === id
                ? 'checkbox_item--checked' : ''
        }`
    }

    return (
        <li className={getClassNameCheckboxItem()}>
            <div className="checkbox_item__in" onClick={ onOptionChange }>
                <div className="checkbox__element">
                    <div className="checkbox__label">
                        <div className="checkbox__img" ref={refImg}>
                            <img src={ img ? img : '/assets/images/no-image.svg' } alt=""/>
                        </div>
                        <div className="checkbox__label_in" style={ styleLabelIn }>
                            <span className="checkbox__title">{ title }</span>
                            {
                                price &&
                                <span className="checkbox__price"><span>
                                    {group === configName.model
                                        ? [0, '0'].includes(price) ? price : `+${price}`
                                        : price
                                    }
                                </span><span>€</span></span>
                            }
                        </div>
                    </div>
                </div>

                <TooltipButton item={ selectedOption } />
            </div>
        </li>
    )
}