/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 Hocker_massiv.glb 
*/

import React, {useContext} from 'react'
import { useGLTF } from '@react-three/drei'
import {OptionsContext} from '../../../App.js'

export function Hocker_massiv({material, ...props}) {
  const {nodes } = useGLTF('/assets/models/bench/Hocker_massiv.glb')
    const {ref} = useContext(OptionsContext)

  return (
    <group {...props} dispose={null} ref={ref.bench.stool} name="Hocker_massiv" visible={false}>
        <mesh geometry={nodes.Plane002.geometry} material={material} position={[0.299, 0.451, 0.18]} />
        <mesh geometry={nodes.Plane003.geometry} material={material} position={[0.299, 0.252, 0.18]} />
        <mesh geometry={nodes.Cube001.geometry} material={material} position={[0.299, 0.41, 0.181]} />
    </group>
  )
}

useGLTF.preload('/assets/models/bench/Hocker_massiv.glb')
