/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 Luxus.glb 
*/

import React, {useContext} from 'react'
import {useGLTF} from '@react-three/drei'
import {OptionsContext} from '../../../App.js'

export function Luxus({material, ...props}) {
    const {nodes} = useGLTF('/assets/models/bench/Luxus.glb')
    const {nodes: nodesNew} = useGLTF('/assets/models/bench/Luxus_new.glb')
    const {nodes: nodesPrestige} = useGLTF('/assets/models/bench/Prestige_new.glb')
    const {ref} = useContext(OptionsContext)

    return (
        <group {...props} dispose={null} ref={ref.bench.lux.set} name="Luxus" visible={false}>
            <group ref={ref.bench.lux.c}>
                <group ref={ref.bench.lux.c1}>
                    <group>
                        <mesh geometry={nodesNew.Cube017.geometry} material={material} position={[1.043, 0.844, 0.066]}
                              rotation={[-0.298, Math.PI / 2, 0]}/>
                        <mesh geometry={nodesNew.Cube019.geometry} material={material} position={[1.082, 0.964, 0.029]}
                              rotation={[-0.298, Math.PI / 2, 0]}/>
                        <mesh geometry={nodesNew.Cube056.geometry} material={material} position={[1.082, 0.723, 0.103]}
                              rotation={[-0.298, Math.PI / 2, 0]}/>
                        <mesh geometry={nodesNew.Circle.geometry} material={material} position={[1.003, 0.86, 0.064]}
                              rotation={[-1.869, 0, -Math.PI]}/>
                        <mesh geometry={nodesPrestige.Cube061.geometry} material={material}
                              position={[0.255, .765, 0.028]}/>
                        <mesh geometry={nodesPrestige.Cube062.geometry} material={material}
                              position={[0.996, .765, 0.028]}/>
                        <mesh geometry={nodesPrestige.Cube097.geometry} material={material}
                              position={[1.9, .765, 0.028]}/>
                    </group>

                    <group>
                        <mesh geometry={nodesNew.Cube090.geometry} material={material} position={[0.919, 0.966, 0.029]}
                              rotation={[-0.298, -Math.PI / 2, 0]}/>
                        <mesh geometry={nodesNew.Cube091.geometry} material={material} position={[0.919, 0.725, 0.103]}
                              rotation={[-0.298, -Math.PI / 2, 0]}/>
                        <mesh geometry={nodesNew.Cube095.geometry} material={material} position={[0.961, 0.844, 0.066]}
                              rotation={[-0.298, Math.PI / 2, 0]}/>
                        <mesh geometry={nodesNew.Circle.geometry} material={material} position={[1.003, 0.86, 0.064]}
                              rotation={[-1.869, 0, -Math.PI]}/>
                        <mesh geometry={nodesPrestige.Cube061.geometry} material={material}
                              position={[0.1, .765, 0.028]}/>
                        <mesh geometry={nodesPrestige.Cube062.geometry} material={material}
                              position={[0.996, .765, 0.028]}/>
                        <mesh geometry={nodesPrestige.Cube097.geometry} material={material}
                              position={[1.737, .765, 0.028]}/>
                    </group>

                    <group>
                        <mesh geometry={nodes.Cube048.geometry} material={material}/>
                        <mesh geometry={nodes.Cube047.geometry} material={material}/>
                        <mesh geometry={nodes.Cube046.geometry} material={material}/>
                        <mesh geometry={nodes.Cube045.geometry} material={material}/>
                        <mesh geometry={nodes.Cube044.geometry} material={material}/>
                        <mesh geometry={nodes.Cube043.geometry} material={material}/>

                        <mesh geometry={nodes.Text.geometry} material={material}/>
                        <mesh geometry={nodes.Cube040.geometry} material={material}/>
                        <mesh geometry={nodes.Cube041.geometry} material={material}/>
                        <mesh geometry={nodes.Circle001.geometry} material={material}/>
                        <mesh geometry={nodes.Cube042.geometry} material={material}/>
                        <mesh geometry={nodes.Cube049.geometry} material={material}/>
                        <mesh geometry={nodes.Cube050.geometry} material={material}/>
                        <mesh geometry={nodes.Cube051.geometry} material={material}/>

                        <mesh geometry={nodes.Cube038.geometry} material={material}/>
                        <mesh geometry={nodes.Cube037.geometry} material={material}/>
                        <mesh geometry={nodes.Cube036.geometry} material={material}/>
                        <mesh geometry={nodes.Cube035.geometry} material={material}/>
                        <mesh geometry={nodes.Cube034.geometry} material={material}/>
                    </group>
                </group>
                <group ref={ref.bench.lux.c2}>
                    <group>
                        <mesh geometry={nodesNew.Cube026.geometry} material={material} position={[0.103, 0.725, 1.092]}
                              rotation={[Math.PI, 0, 2.844]}/>
                        <mesh geometry={nodesNew.Cube027.geometry} material={material} position={[0.066, 0.845, 1.079]}
                              rotation={[Math.PI, 0, 2.844]}/>
                        <mesh geometry={nodesNew.Cube055.geometry} material={material} position={[0.029, 0.965, 1.092]}
                              rotation={[Math.PI, 0, 2.844]}/>
                        <mesh geometry={nodesNew.Circle002.geometry} material={material} position={[0.058, 0.86, 1.066]}
                              rotation={[-Math.PI / 2, -0.298, Math.PI / 2]}/>
                        <mesh geometry={nodesPrestige.Cube056.geometry} material={material}
                              position={[.028, .765, 0.28]}/>
                        <mesh geometry={nodesPrestige.Cube056.geometry} material={material}
                              position={[.028, .765, 1.1]}/>
                        <mesh geometry={nodesPrestige.Cube056.geometry} material={material}
                              position={[.028, .765, 1.9]}/>
                    </group>

                    <group>
                        <mesh geometry={nodes.Cube014.geometry} material={material}/>
                        <mesh geometry={nodes.Cube013.geometry} material={material}/>
                        <mesh geometry={nodes.Cube008.geometry} material={material}/>
                        <mesh geometry={nodes.Cube007.geometry} material={material}/>
                        <mesh geometry={nodes.Cube002.geometry} material={material}/>
                        <mesh geometry={nodes.Cube001.geometry} material={material}/>
                    </group>
                </group>
                <group ref={ref.bench.lux.c3}>
                    <group>
                        <mesh geometry={nodesNew.Cube024.geometry} material={material} position={[1.934, 0.845, 1.079]}
                              rotation={[0, 0, -0.298]}/>
                        <mesh geometry={nodesNew.Cube020.geometry} material={material} position={[1.897, 0.725, 1.092]}
                              rotation={[0, 0, -0.298]}/>
                        <mesh geometry={nodesNew.Cube025.geometry} material={material} position={[1.97, 0.965, 1.092]}
                              rotation={[0, 0, -0.298]}/>
                        <mesh geometry={nodesNew.Circle003.geometry} material={material} position={[1.941, 0.86, 1.066]}
                              rotation={[-Math.PI / 2, 0.298, -Math.PI / 2]}/>
                        <mesh geometry={nodesPrestige.Cube015.geometry} material={material}
                              position={[1.971, .765, 0.28]}/>
                        <mesh geometry={nodesPrestige.Cube015.geometry} material={material}
                              position={[1.971, .765, 1.1]}/>
                        <mesh geometry={nodesPrestige.Cube015.geometry} material={material}
                              position={[1.971, .765, 1.9]}/>
                    </group>

                    <group>
                        <mesh geometry={nodes.Cube033.geometry} material={material}/>
                        <mesh geometry={nodes.Cube032.geometry} material={material}/>
                        <mesh geometry={nodes.Cube031.geometry} material={material}/>
                        <mesh geometry={nodes.Cube030.geometry} material={material}/>
                        <mesh geometry={nodes.Cube029.geometry} material={material}/>
                        <mesh geometry={nodes.Cube028.geometry} material={material}/>
                    </group>
                </group>
            </group>

            <group ref={ref.bench.lux.b}>
                <group>
                    <mesh geometry={nodesNew.Cube060.geometry} material={material} position={[1.002, 0.844, 0.066]}
                          rotation={[-0.298, Math.PI / 2, 0]}/>
                    <mesh geometry={nodesNew.Cube061.geometry} material={material} position={[0.999, 0.725, 0.103]}
                          rotation={[-0.298, -Math.PI / 2, 0]}/>
                    <mesh geometry={nodesNew.Cube062.geometry} material={material} position={[0.999, 0.966, 0.029]}
                          rotation={[-0.298, -Math.PI / 2, 0]}/>
                    <mesh geometry={nodesNew.Circle007.geometry} material={material} position={[1.003, 0.86, 0.064]}
                          rotation={[-1.869, 0, -Math.PI]}/>

                    <mesh geometry={nodesPrestige.Cube061.geometry} material={material}
                          position={[0.255, .765, 0.028]}/>
                    <mesh geometry={nodesPrestige.Cube062.geometry} material={material}
                          position={[0.996, .765, 0.028]}/>
                    <mesh geometry={nodesPrestige.Cube097.geometry} material={material}
                          position={[1.737, .765, 0.028]}/>
                </group>

                <mesh geometry={nodes.Cube048.geometry} material={material}/>
                <mesh geometry={nodes.Cube047.geometry} material={material}/>
                <mesh geometry={nodes.Cube046.geometry} material={material}/>
                <mesh geometry={nodes.Cube045.geometry} material={material}/>
                <mesh geometry={nodes.Cube044.geometry} material={material}/>
                <mesh geometry={nodes.Cube043.geometry} material={material}/>

                <mesh geometry={nodes.Text.geometry} material={material}/>
                <mesh geometry={nodes.Cube040.geometry} material={material}/>
                <mesh geometry={nodes.Cube041.geometry} material={material}/>
                <mesh geometry={nodes.Circle001.geometry} material={material}/>
                <mesh geometry={nodes.Cube042.geometry} material={material}/>
                <mesh geometry={nodes.Cube049.geometry} material={material}/>
                <mesh geometry={nodes.Cube050.geometry} material={material}/>
                <mesh geometry={nodes.Cube051.geometry} material={material}/>

                <mesh geometry={nodes.Cube038.geometry} material={material}/>
                <mesh geometry={nodes.Cube037.geometry} material={material}/>
                <mesh geometry={nodes.Cube036.geometry} material={material}/>
                <mesh geometry={nodes.Cube035.geometry} material={material}/>
                <mesh geometry={nodes.Cube034.geometry} material={material}/>
            </group>

            <group ref={ref.bench.lux.d}>
                <group ref={ref.bench.lux.d1}>
                    <mesh geometry={nodesNew.Cube081.geometry} material={material} position={[0.029, 0.965, 1]}
                          rotation={[Math.PI, 0, 2.844]}/>
                    <mesh geometry={nodesNew.Cube082.geometry} material={material} position={[0.066, 0.845, 1]}
                          rotation={[Math.PI, 0, 2.844]}/>
                    <mesh geometry={nodesNew.Cube083.geometry} material={material} position={[0.103, 0.725, 1]}
                          rotation={[Math.PI, 0, 2.844]}/>
                    <mesh geometry={nodesNew.Circle005.geometry} material={material} position={[0.058, 0.86, 1]}
                          rotation={[-Math.PI / 2, -0.298, Math.PI / 2]}/>
                    <mesh geometry={nodesPrestige.Cube056.geometry} material={material} position={[.028, .765, 0.09]}/>
                    <mesh geometry={nodesPrestige.Cube056.geometry} material={material} position={[.028, .765, 1]}/>
                    <mesh geometry={nodesPrestige.Cube056.geometry} material={material} position={[.028, .765, 1.9]}/>

                    <mesh geometry={nodes.Cube009.geometry} material={material} position={[0.5, 0.481, 1]}
                          rotation={[Math.PI, 0, Math.PI]} scale={[0.766, 0.705, 1]}/>
                    <mesh geometry={nodes.Cube010.geometry} material={material} position={[0.408, 0.481, 1]}
                          rotation={[Math.PI, 0, Math.PI]} scale={[0.766, 0.705, 1]}/>
                    <mesh geometry={nodes.Cube011.geometry} material={material} position={[0.316, 0.481, 1]}
                          rotation={[Math.PI, 0, Math.PI]} scale={[0.766, 0.705, 1]}/>
                    <mesh geometry={nodes.Cube012.geometry} material={material} position={[0.224, 0.481, 1]}
                          rotation={[Math.PI, 0, Math.PI]} scale={[0.766, 0.705, 1]}/>
                    <mesh geometry={nodes.Cube016.geometry} material={material} position={[0.132, 0.481, 1]}
                          rotation={[Math.PI, 0, Math.PI]} scale={[0.766, 0.705, 1]}/>
                    <mesh geometry={nodes.Cube018.geometry} material={material} position={[0.04, 0.481, 1]}
                          rotation={[Math.PI, 0, Math.PI]} scale={[0.766, 0.705, 1]}/>
                </group>

                <group ref={ref.bench.lux.d2}>
                    <mesh geometry={nodesNew.Cube084.geometry} material={material} position={[1.971, 0.965, 1]}
                          rotation={[0, 0, -0.298]}/>
                    <mesh geometry={nodesNew.Cube085.geometry} material={material} position={[1.934, 0.845, 1.001]}
                          rotation={[0, 0, -0.298]}/>
                    <mesh geometry={nodesNew.Cube086.geometry} material={material} position={[1.897, 0.725, 1]}
                          rotation={[0, 0, -0.298]}/>
                    <mesh geometry={nodesNew.Circle004.geometry} material={material} position={[1.942, 0.86, 1]}
                          rotation={[-Math.PI / 2, 0.298, -Math.PI / 2]}/>
                    <mesh geometry={nodesPrestige.Cube015.geometry} material={material} position={[1.971, .765, 0.09]}/>
                    <mesh geometry={nodesPrestige.Cube015.geometry} material={material} position={[1.971, .765, 1]}/>
                    <mesh geometry={nodesPrestige.Cube015.geometry} material={material} position={[1.971, .765, 1.9]}/>

                    <mesh geometry={nodes.Cube021.geometry} material={material} position={[1.5, 0.481, 1]}
                          scale={[0.766, 0.705, 1]}/>
                    <mesh geometry={nodes.Cube022.geometry} material={material} position={[1.592, 0.481, 1]}
                          scale={[0.766, 0.705, 1]}/>
                    <mesh geometry={nodes.Cube023.geometry} material={material} position={[1.684, 0.481, 1]}
                          scale={[0.766, 0.705, 1]}/>
                    <mesh geometry={nodes.Cube052.geometry} material={material} position={[1.776, 0.481, 1]}
                          scale={[0.766, 0.705, 1]}/>
                    <mesh geometry={nodes.Cube053.geometry} material={material} position={[1.868, 0.481, 1]}
                          scale={[0.766, 0.705, 1]}/>
                    <mesh geometry={nodes.Cube054.geometry} material={material} position={[1.96, 0.481, 1]}
                          scale={[0.766, 0.705, 1]}/>
                </group>

                <group ref={ref.bench.lux.d3}>
                    <mesh geometry={nodes.Cube038.geometry} material={material} position={[0, 0, -.0908]}/>
                    <mesh geometry={nodes.Cube038.geometry} material={material}/>
                    <mesh geometry={nodes.Cube037.geometry} material={material}/>
                    <mesh geometry={nodes.Cube036.geometry} material={material}/>
                    <mesh geometry={nodes.Cube035.geometry} material={material}/>
                    <mesh geometry={nodes.Cube034.geometry} material={material}/>
                </group>
            </group>


        </group>
    )
}

useGLTF.preload('/assets/models/bench/Luxus.glb')
useGLTF.preload('/assets/models/bench/Luxus_new.glb')
useGLTF.preload('/assets/models/bench/Prestige_new.glb')
