/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 LED_Spot_bronze.glb 
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Model(props) {
  const { nodes, materials } = useGLTF('/assets/models/lamp/LED_Spot_bronze.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Circle.geometry} material={materials['Material.003']} />
      <mesh geometry={nodes.Circle001.geometry} material={materials['Material.003']} />
      <mesh geometry={nodes.Circle002_1.geometry} material={materials.Emission} />
      <mesh geometry={nodes.Circle002_2.geometry} material={materials['Material.003']} />
    </group>
  )
}

export function ModelMinimize(props) {
    const { nodes, materials } = useGLTF('/assets/models/lamp/LED_Spot_bronze.glb')
    return (
        <group {...props} dispose={null}>
            <mesh geometry={nodes.Circle002_1.geometry} material={materials.Emission} />
            <mesh geometry={nodes.Circle001.geometry} material={materials['Material.003']} />
        </group>
    )
}

useGLTF.preload('/assets/models/lamp/LED_Spot_bronze.glb')
