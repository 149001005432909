/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 Exclusiv.glb 
*/

import React, {useContext} from 'react'
import { useGLTF } from '@react-three/drei'
import {OptionsContext} from '../../../App'

export function Exclusiv({material, ...props}) {
  const { nodes } = useGLTF('/assets/models/bench/Exclusiv.glb')
    const {ref} = useContext(OptionsContext)
  return (
    <group {...props} dispose={null} ref={ref.bench.exclusive.set} name="Exclusive" visible={false}>
        <group ref={ref.bench.exclusive.c} visible={false}>
            <group ref={ref.bench.exclusive.c1}>
                <group visible={false}>
                    <mesh geometry={nodes.Cube098.geometry} material={material} />
                    <mesh geometry={nodes.Cube099.geometry} material={material} />
                    <mesh geometry={nodes.Cube100.geometry} material={material} />
                    <mesh geometry={nodes.Cube101.geometry} material={material} />
                    <mesh geometry={nodes.Cube102.geometry} material={material} position-x={.08}/>
                    <mesh geometry={nodes.Cube103.geometry} material={material} position-x={.08}/>

                    <mesh geometry={nodes.Cube080.geometry} material={material} />
                    <mesh geometry={nodes.Cube083.geometry} material={material} />
                    <mesh geometry={nodes.Cube084.geometry} material={material} />
                    <mesh geometry={nodes.Cube104.geometry} material={material} />
                    <mesh geometry={nodes.Cube105.geometry} material={material} />
                </group>

                <group>
                    <mesh geometry={nodes.Cube098.geometry} material={material} position-x={- .08}/>
                    <mesh geometry={nodes.Cube099.geometry} material={material} position-x={- .08}/>
                    <mesh geometry={nodes.Cube100.geometry} material={material} />
                    <mesh geometry={nodes.Cube101.geometry} material={material} />
                    <mesh geometry={nodes.Cube102.geometry} material={material} />
                    <mesh geometry={nodes.Cube103.geometry} material={material} />

                    <mesh geometry={nodes.Cube135.geometry} material={material} />
                    <mesh geometry={nodes.Cube136.geometry} material={material} />
                    <mesh geometry={nodes.Cube137.geometry} material={material} />
                    <mesh geometry={nodes.Cube138.geometry} material={material} />
                    <mesh geometry={nodes.Cube139.geometry} material={material} />
                </group>

                <group>
                    <mesh geometry={nodes.Cube048.geometry} material={material} />
                    <mesh geometry={nodes.Cube047.geometry} material={material} />
                    <mesh geometry={nodes.Cube046.geometry} material={material} />
                    <mesh geometry={nodes.Cube045.geometry} material={material} />
                    <mesh geometry={nodes.Cube044.geometry} material={material} />
                    <mesh geometry={nodes.Cube043.geometry} material={material} />

                    <mesh geometry={nodes.Cube042.geometry} material={material} />
                    <mesh geometry={nodes.Cube049.geometry} material={material} />
                    <mesh geometry={nodes.Cube050.geometry} material={material} />
                    <mesh geometry={nodes.Cube051.geometry} material={material} />
                    <mesh geometry={nodes.Cube057.geometry} material={material} />
                    <mesh geometry={nodes.Cube058.geometry} material={material} />
                    <mesh geometry={nodes.Cube059.geometry} material={material} />
                    <mesh geometry={nodes.Cube060.geometry} material={material} />

                    <mesh geometry={nodes.Cube038.geometry} material={material} />
                    <mesh geometry={nodes.Cube037.geometry} material={material} />
                    <mesh geometry={nodes.Cube036.geometry} material={material} />
                    <mesh geometry={nodes.Cube035.geometry} material={material} />
                    <mesh geometry={nodes.Cube034.geometry} material={material} />
                </group>
            </group>

            <group ref={ref.bench.exclusive.c2}>
                <group>
                    <mesh geometry={nodes.Cube062.geometry} material={material} />
                    <mesh geometry={nodes.Cube085.geometry} material={material} />
                    <mesh geometry={nodes.Cube025.geometry} material={material} />
                    <mesh geometry={nodes.Cube055.geometry} material={material} />
                    <mesh geometry={nodes.Cube056.geometry} material={material} />

                    <mesh geometry={nodes.Cube086.geometry} material={material} />
                    <mesh geometry={nodes.Cube087.geometry} material={material} />
                    <mesh geometry={nodes.Cube088.geometry} material={material} />
                    <mesh geometry={nodes.Cube089.geometry} material={material} />
                    <mesh geometry={nodes.Cube090.geometry} material={material} position-z={.12}/>
                    <mesh geometry={nodes.Cube091.geometry} material={material} position-z={.12}/>
                </group>

                <group>
                    <mesh geometry={nodes.Cube014.geometry} material={material} />
                    <mesh geometry={nodes.Cube013.geometry} material={material} />
                    <mesh geometry={nodes.Cube008.geometry} material={material} />
                    <mesh geometry={nodes.Cube007.geometry} material={material} />
                    <mesh geometry={nodes.Cube002.geometry} material={material} />
                    <mesh geometry={nodes.Cube001.geometry} material={material} />
                </group>
            </group>

            <group ref={ref.bench.exclusive.c3}>
                <group>
                    <mesh geometry={nodes.Cube015.geometry} material={material} />
                    <mesh geometry={nodes.Cube019.geometry} material={material} />
                    <mesh geometry={nodes.Cube020.geometry} material={material} />
                    <mesh geometry={nodes.Cube078.geometry} material={material} />
                    <mesh geometry={nodes.Cube092.geometry} material={material} />
                    <mesh geometry={nodes.Cube093.geometry} material={material} />
                    <mesh geometry={nodes.Cube094.geometry} material={material} />
                    <mesh geometry={nodes.Cube095.geometry} material={material} />
                    <mesh geometry={nodes.Cube096.geometry} material={material} />
                    <mesh geometry={nodes.Cube097.geometry} material={material} position-z={- .12}/>
                    <mesh geometry={nodes.Cube107.geometry} material={material} position-z={- .12}/>
                </group>

                <group>
                    <mesh geometry={nodes.Cube033.geometry} material={material} />
                    <mesh geometry={nodes.Cube032.geometry} material={material} />
                    <mesh geometry={nodes.Cube031.geometry} material={material} />
                    <mesh geometry={nodes.Cube030.geometry} material={material} />
                    <mesh geometry={nodes.Cube029.geometry} material={material} />
                    <mesh geometry={nodes.Cube028.geometry} material={material} />
                </group>
            </group>
        </group>

        <group ref={ref.bench.exclusive.b}>
            <group>
                <mesh geometry={nodes.Cube124.geometry} material={material} />
                <mesh geometry={nodes.Cube125.geometry} material={material} />
                <mesh geometry={nodes.Cube126.geometry} material={material} />
                <mesh geometry={nodes.Cube127.geometry} material={material} />
                <mesh geometry={nodes.Cube128.geometry} material={material} />
                <mesh geometry={nodes.Cube129.geometry} material={material} />
                <mesh geometry={nodes.Cube130.geometry} material={material} />
                <mesh geometry={nodes.Cube131.geometry} material={material} />
                <mesh geometry={nodes.Cube132.geometry} material={material} />
                <mesh geometry={nodes.Cube133.geometry} material={material} />
                <mesh geometry={nodes.Cube134.geometry} material={material} />
            </group>

            <mesh geometry={nodes.Cube048.geometry} material={material} />
            <mesh geometry={nodes.Cube047.geometry} material={material} />
            <mesh geometry={nodes.Cube046.geometry} material={material} />
            <mesh geometry={nodes.Cube045.geometry} material={material} />
            <mesh geometry={nodes.Cube044.geometry} material={material} />
            <mesh geometry={nodes.Cube043.geometry} material={material} />

            <mesh geometry={nodes.Cube042.geometry} material={material} />
            <mesh geometry={nodes.Cube049.geometry} material={material} />
            <mesh geometry={nodes.Cube050.geometry} material={material} />
            <mesh geometry={nodes.Cube051.geometry} material={material} />
            <mesh geometry={nodes.Cube057.geometry} material={material} />
            <mesh geometry={nodes.Cube058.geometry} material={material} />
            <mesh geometry={nodes.Cube059.geometry} material={material} />
            <mesh geometry={nodes.Cube060.geometry} material={material} />

            <mesh geometry={nodes.Cube038.geometry} material={material} />
            <mesh geometry={nodes.Cube037.geometry} material={material} />
            <mesh geometry={nodes.Cube036.geometry} material={material} />
            <mesh geometry={nodes.Cube035.geometry} material={material} />
            <mesh geometry={nodes.Cube034.geometry} material={material} />
        </group>

        <group ref={ref.bench.exclusive.d} visible={false}>
            <group ref={ref.bench.exclusive.d1}>
                <mesh geometry={nodes.Cube017.geometry} material={material} />
                <mesh geometry={nodes.Cube024.geometry} material={material} />
                <mesh geometry={nodes.Cube040.geometry} material={material} />
                <mesh geometry={nodes.Cube110.geometry} material={material} />
                <mesh geometry={nodes.Cube111.geometry} material={material} />
                <mesh geometry={nodes.Cube112.geometry} material={material} />
                <mesh geometry={nodes.Cube113.geometry} material={material} />

                <mesh geometry={nodes.Cube009.geometry} material={material} />
                <mesh geometry={nodes.Cube010.geometry} material={material} />
                <mesh geometry={nodes.Cube011.geometry} material={material} />
                <mesh geometry={nodes.Cube012.geometry} material={material} />
                <mesh geometry={nodes.Cube016.geometry} material={material} />
                <mesh geometry={nodes.Cube018.geometry} material={material} />
            </group>

            <group ref={ref.bench.exclusive.d2}>
                <mesh geometry={nodes.Cube061.geometry} material={material} />
                <mesh geometry={nodes.Cube063.geometry} material={material} />
                <mesh geometry={nodes.Cube082.geometry} material={material} />
                <mesh geometry={nodes.Cube108.geometry} material={material} />
                <mesh geometry={nodes.Cube109.geometry} material={material} />
                <mesh geometry={nodes.Cube114.geometry} material={material} />
                <mesh geometry={nodes.Cube115.geometry} material={material} />

                <mesh geometry={nodes.Cube021.geometry} material={material} />
                <mesh geometry={nodes.Cube022.geometry} material={material} />
                <mesh geometry={nodes.Cube023.geometry} material={material} />
                <mesh geometry={nodes.Cube052.geometry} material={material} />
                <mesh geometry={nodes.Cube053.geometry} material={material} />
                <mesh geometry={nodes.Cube054.geometry} material={material} />
            </group>

            <group ref={ref.bench.exclusive.d3}>
                <mesh geometry={nodes.Cube038.geometry} material={material} position={[0, 0, -.0908]}/>
                <mesh geometry={nodes.Cube038.geometry} material={material} />
                <mesh geometry={nodes.Cube037.geometry} material={material} />
                <mesh geometry={nodes.Cube036.geometry} material={material} />
                <mesh geometry={nodes.Cube035.geometry} material={material} />
                <mesh geometry={nodes.Cube034.geometry} material={material} />
            </group>
        </group>
    </group>
  )
}

useGLTF.preload('/assets/models/bench/Exclusiv.glb')
