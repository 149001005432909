import {MeshTransmissionMaterial} from '@react-three/drei'
import * as THREE from 'three'
import React, {useContext, useMemo} from 'react'
import {configName} from '../../helpers/constants.js'
import {getRendererOptions} from '../../helpers/functions.js'
import {OptionsContext} from '../../App.js'

export default function Glass({ geometry, noEdge, ...params }) {
    const { config } = useContext( OptionsContext )

    const glassType = useMemo(() => {
        return getRendererOptions({ config, groupName: configName.glassType, selected: true }).name
    }, [config[configName.glassType].value])

    const materialProps = {
        glass: {
            clear: { // чистий
                samples: 16,
                resolution: 2048,
                toneMapped: true,
                color: new THREE.Color('#ffffff'),
                thickness: 0, // товщина
                roughness: 0, // шорсткість
                anisotropicBlur: 0, // анізотропне розмиття
            },
            bronze: { // бронзовий
                samples: 16,
                resolution: 2048,
                toneMapped: true,
                color: new THREE.Color('#755518'),
                thickness: 0.005,
                roughness: 0,
                anisotropicBlur: 5,
            },
            frosted: { // матовий
                samples: 16,
                resolution: 2048,
                toneMapped: true,
                color: new THREE.Color('#ffffff'),
                thickness: 0.1,
                roughness: 0.4,
                anisotropicBlur: 7,
            },
            schwarz: { // чорний
                samples: 16,
                resolution: 2048,
                toneMapped: true,
                color: new THREE.Color('#5e5e5e'),
                thickness: 0.005,
                roughness: 0,
                anisotropicBlur: 5,
            }
        }
    }

    const glassEdgesMaterial = new THREE.MeshStandardMaterial({ color: '#888888' })

    return (
        <group name="glass" {...params}>
            <mesh geometry={geometry}>
                <MeshTransmissionMaterial transmissionSampler transparent {...materialProps.glass[glassType]} />
            </mesh>
            <mesh
                geometry={geometry}
                material={[
                    noEdge === 'left' ? null : glassEdgesMaterial,
                    noEdge === 'right' ? null : glassEdgesMaterial,
                    glassEdgesMaterial, // up
                    glassEdgesMaterial, // down
                    null,
                    null,
                ]}
            />
        </group>
    )
}