import './tooltip.css'
import {Icon} from "../../icon";
import {useContext} from "react";
import {OptionsContext} from "../../../App";

export default function Tooltip() {
    const { tooltipRef } = useContext( OptionsContext )

    function handlerCloseTooltipButton() {
        tooltipRef.current.classList.add('hide')
    }

    return (
        <>
            <button className="close_tooltip_button" onClick={ handlerCloseTooltipButton }>
                <span className="icon_wrapper">
                    <Icon icon="close" className="icon" />
                </span>
            </button>
            <div className="image"><img src="" alt=""/></div>
            <div className="title"></div>
            <div className="content"></div>
            <div className="arrow"></div>
        </>
    )
}
