import React, {useContext, useEffect, useMemo, useRef} from 'react'
import {Base, Geometry, Subtraction} from '@react-three/csg'
import {configName} from '../../../helpers/constants'
import {OptionsContext} from '../../../App.js'
import * as THREE from "three";
import {setRepeatTexture} from "../../../helpers/functions";
import {useTexture, Environment, Decal, RenderTexture, Text, AccumulativeShadows, RandomizedLight, PerspectiveCamera, OrbitControls} from '@react-three/drei'

export default function AthenWatermark({size, wall}) {

    const { config } = useContext( OptionsContext )

    const watermarkRef = useRef()

    useEffect(() => {
        const roomWalls = config[configName.roomWalls].value.optionsSelected[0].id

        watermarkRef.current.visible = !(
            !roomWalls
            || wall === 'corner'
            || roomWalls === 1 && (wall === 'right' || wall === 'front')
            || roomWalls === 2 && wall === 'front'
            || roomWalls === 3 && (wall === 'left' || wall === 'front')
            || roomWalls === 4 && (wall === 'left' || wall === 'right' || wall === 'front')
            // || roomWalls !== 5 && wall === 'wall_front'
        )
    }, [config[configName.roomWalls].value])

    const [texture1] = useTexture(['/assets/textures/text_1.jpg'])

    const watermark = useMemo(() => {
        texture1.rotation = THREE.MathUtils.degToRad(225)
        texture1.wrapS = texture1.wrapT = THREE.RepeatWrapping
        texture1.repeat.set( size.model.width/.6, size.model.height/.3 )
        texture1.needsUpdate = true

        return texture1

    }, [])

    return useMemo(() => {
        let width = size.model[ wall === 'back' ? 'width' : 'depth' ]
        let rotation = [Math.PI, 0, 0]

        if (wall === 'front') {
            width = size.model.wFront
            rotation = [0, Math.PI, Math.PI]
        } else if (wall === 'right') {
            width = size.model.wRight
            rotation = [0, Math.PI, Math.PI]
        }

        return (
            <mesh ref={watermarkRef} position-z={-.04102} rotation={rotation} visible={false}>
                <planeGeometry args={[width, size.model.height]} />
                <meshStandardMaterial
                    color={'#ffffff'}
                    transparent
                    opacity={.4}
                    aoMap={watermark}
                    rotation={[Math.PI, 0, 0]}
                    side={2}
                />
            </mesh>
        )
    }, [size])
}