import { ReactComponent as arrow_up } from './assets/arrow_up.svg'
import { ReactComponent as arrow_right } from './assets/arrow_right.svg'
import { ReactComponent as arrow_left } from './assets/arrow_left.svg'
import { ReactComponent as arrows_4 } from './assets/arrows_4.svg'
import { ReactComponent as i } from './assets/i.svg'
import { ReactComponent as info } from './assets/info.svg'
import { ReactComponent as info_fill } from './assets/info_fill.svg'
import { ReactComponent as checked } from './assets/checked.svg'
import { ReactComponent as reset } from './assets/reset.svg'
import { ReactComponent as tab_0 } from './assets/tabs/0.svg'
import { ReactComponent as tab_1 } from './assets/tabs/1.svg'
import { ReactComponent as tab_2 } from './assets/tabs/2.svg'
import { ReactComponent as tab_3 } from './assets/tabs/3.svg'
import { ReactComponent as close } from './assets/close.svg'
import { ReactComponent as spinner } from './assets/spinner.svg'


export const icons = {
    arrow_left,
    arrow_right,
    arrow_up,
    arrows_4,
    i,
    info,
    info_fill,
    checked,
    reset,
    tab_0,
    tab_1,
    tab_2,
    tab_3,
    close,
    spinner,
}