import {useContext, useEffect, useRef} from 'react'
import axios from 'axios'

import {OptionsContext} from '../../../App.js'
import Sizes from '../size/Sizes.js'
import OptionGroup from '../optionGroup/OptionGroup.js'
import {configName} from '../../../helpers/constants.js'
import {getArrayOptionsSortTabs, getRendererOptions} from '../../../helpers/functions.js'
import './optionsPanel.css'
import {Icon} from '../../icon'

export default function OptionsPanel() {

    const { config, tabs, tooltipRef, showOptionPanel, priceTable } = useContext( OptionsContext )

    if (!priceTable) return null

    const refPanel = useRef()
    const refFooter = useRef()

    let offsetScrollTooltip = 0
    let lastScrollY = 0

    function handleOptionPanelScroll( event ) {
        event.preventDefault()
        const scrollY = event.target.scrollTop

        const scrollDir = scrollY - lastScrollY > 0 ? 'down' : 'up'

        lastScrollY = scrollY

        if (window.innerWidth < 768) {
            const scrolledDown = event.target.scrollHeight - event.target.scrollTop

            // Логіка для футера опцій.
            //  1) Ховати/показувати при скролі вниз/вгору

            if (scrollDir === 'down') {
                refFooter.current.classList.add('show_half')
            } else {
                refFooter.current.classList.remove('show_half')
            }

            // Логіка для футера опцій.
            //  2) Показувати при скроленні повністю вниз

            if (scrolledDown - event.target.offsetHeight < 4) {
                refFooter.current.classList.add('show')
                refFooter.current.classList.remove('show_half')
            } else {
                refFooter.current.classList.remove('show')
            }
        }

        if ( Math.abs( scrollY - offsetScrollTooltip ) > 100 ) {
            tooltipRef.current.classList.add('hide')
            offsetScrollTooltip = scrollY
        }
    }

    function handleBodyClick( event ) {
        if ( ! ( event.target.closest('.option_small_tooltip') || event.target.closest('.checkbox__tooltip_button') ) ) {
            tooltipRef.current.classList.add('hide')
        }
    }

    useEffect(() => {
        const preloadTextHtml = document.getElementById('preload_text')
        if (preloadTextHtml) {
            preloadTextHtml.style.display = 'none'
        }

        document.body.addEventListener('click', handleBodyClick)

        return () => { document.body.removeEventListener('click', handleBodyClick) }
    }, [])

    /**
     * При переключенні на таби, меню скролиться вверх
     */
    useEffect(() => {
        if (refPanel.current) {
            refPanel.current.scrollTop = 0
        }
    }, [tabs])

    function handleFormSubmit(e) {
        e.preventDefault()
        let date = new Date()
        const currentDate = date.toISOString().split('T')[0]
        console.log('form submit.')

        const data = {
            options: getArrayOptionsSortTabs(config),
            totalPrice: 1234567, //totalPrice.toFixed(2),
            tabs: tabs,
            word: 'jlJLjl56*&^jkk',
        }

        axios({
            url: 'https://configurator.entsolvebim.pl/api/index.php',
            // url: 'http://localhost/test/api/index.php',
            method: 'post',
            params: {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Content-Length': JSON.stringify(data).length
                },
            },
            data: JSON.stringify(data)
        })
            .then(function(response){
                if (response.data.status === 'ok') {
                    document.cookie="file=" + response.data.file + ";path=/";
                    window.open(response.data.file, '_blank')
                } else {
                    console.log(response.data)
                }
            })
    }

    const optionsSortByTab = tabs.map(t => [])

    Object.keys(config)
        .map(key => config[key])
        .sort((a, b) => a.value.id - b.value.id)
        .forEach( item => {
            const element = item.value.name === configName.size
                ? <Sizes key={ item.value.name } configGroup={ item } />
                : <OptionGroup key={ item.value.name } configGroup={ item } />


            optionsSortByTab[item.value.tab].push(element)
        })

    const tab = tabs.find(t => t.checked)

    return (
        <div id="options_panel" className={ showOptionPanel ? 'show' : 'hide' }>
            <form onSubmit={ handleFormSubmit }>
                <div className="options_panel__in" onScroll={ handleOptionPanelScroll } ref={ refPanel }>
                    <h1 className="options_panel__title"><span>{ tab.title }</span></h1>
                    <ul className="options_panel__options_list">
                        { optionsSortByTab[tab.id] }
                    </ul>
                </div>

                <div className="options_footer" ref={refFooter}>
                    { <Footer /> }
                </div>
            </form>
        </div>
    )
}

function Footer() {
    const { config, tabs, setTabs, priceTable, totalPrice, setTotalPrice } = useContext( OptionsContext )

    function handlerClickTab( tab ) {
        const newTabs = tabs.map(t => {
            t.checked = t.id === tab.id
            return t
        })
        setTabs( newTabs )
    }

    /**
     * Отримати та виставити прайси на кожен тип деревини при зміні розміру
     * @param model Object, модель
     * @param width ширина моделі
     * @param depth глибина моделі
     * @param wood Object, айтем - тип деревини
     * @returns {*|number}
     */
    function getPrice(model, width, depth, wood) {
        const typeWood = wood.name === 'hz0' ? 'fichte' : wood.name === 'hz1' ? 'zirbe': 'rest'

        const table = priceTable[ model ][ typeWood ]

        if (!table) return 0

        const indexCol = table[0].findIndex( row => row === String( width ) )
        const row = table.find( col => col[0] === String( depth ) )
        const price = row?.[ indexCol ]

        if (price) return price
        else return 0
    }

    useEffect(() => {
        const model = config[configName.model].value.optionsSelected[0].name
        const width = Number( (config[configName.size].value.width * 100).toFixed(2) )
        const depth = Number( (config[configName.size].value.depth * 100).toFixed(2) )

        if (model === 'morena') {
            config[configName.typeWood].value.options.forEach(wood => {
                wood.price = 0
            })
            config[configName.typeWood2].value.options.forEach(wood => {
                wood.price = getPrice(model, width, depth, wood)
            })
        } else {
            config[configName.typeWood].value.options.forEach(wood => {
                wood.price = getPrice(model, width, depth, wood)
            })
        }

    }, [
        config[configName.model].value,
        config[configName.size].value,
    ])

    /** Price: Set Total Price */
    useEffect(() => {
        let totalPrice = 0

        Object.keys(config).forEach(key => {
            const { value } = config[key]

            if (value.name !== configName.model) {
                const selectOption = getRendererOptions({config, groupName: value.name, selected: true})
                if (selectOption && selectOption.price) {
                    totalPrice += Number(selectOption.price)
                }
            }
        })

        setTotalPrice( totalPrice.toFixed(2) )
    }, Object.keys(config).map(key => config[key].value))

    return (
        <>
            <div className="options_footer__total_price">
                <div className="title">Preis</div>
                <div className="price">
                    <span className="notification_taxes">Betrag inkl. 19 MwSt</span>
                    <span className="amount">{ totalPrice }<span className="currency_symbol">€</span></span>
                </div>
            </div>
            <div className="options_footer__tabs">
                <ul className="options_footer__tabs_list">
                    {
                        tabs.map( tab => {
                            return (
                                <li className={ `options_footer__tab${ tab.checked ? ' active' : ''}` } key={ tab.id }>
                                    <div className="options_footer__tab_in" onClick={ handlerClickTab.bind( this, tab ) }>
                                        <div className="icon_wrap">
                                            <Icon icon={ `tab_${ tab.id }` } className="icon" />
                                        </div>
                                        <div className="title">{ tab.title }</div>
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>

            <div className="options_footer__button_wrap options_footer__button_wrap--desktop">
                <button className="form_button_summary" type="submit">Zusammenfassung</button>
            </div>

            <div className="options_footer__button_wrap options_footer__button_wrap--mobile">
                <button className="form_button_summary" type="submit">Gesamt</button>
                <div className="price_wrapper">
                    <div className="title">Preis</div>
                    <div className="price">
                        <span className="notification_taxes">Betrag inkl. 19 MwSt</span>
                        <span className="amount">{ totalPrice }<span className="currency_symbol">€</span></span>
                    </div>
                </div>
            </div>
        </>
    )
}