/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.4.1 ding1_Kopie_open_mirror.glb 
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Model(props) {
  const { nodes, materials } = useGLTF('/assets/models/fittings/ding1_Kopie_open_mirror.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Plane001.geometry} material={materials['Material.004']} position={[0.347, 1.048, 0.882]} rotation={[0, 0, -Math.PI / 2]} scale={[1, 1, 0.294]} />
      <mesh geometry={nodes.Plane.geometry} material={materials['Material.004']} position={[1.114, 1.028, 0.964]} rotation={[-Math.PI / 2, Math.PI / 2, 0]} />
      <mesh geometry={nodes.Cylinder.geometry} material={materials['Material.004']} position={[0.449, 1.01, 0.843]} scale={[0.194, 0.095, 0.153]} />
      <mesh geometry={nodes.Plane003.geometry} material={materials['Material.001']} position={[-0.399, 1.039, 0.863]} rotation={[Math.PI / 2, 0, Math.PI]} scale={0.951} />
      <mesh geometry={nodes.Plane004.geometry} material={materials['Material.001']} position={[1.113, 1.039, 0.846]} rotation={[Math.PI / 2, 0, 0]} scale={0.951} />
      <mesh geometry={nodes.Plane005.geometry} material={materials['Material.004']} position={[0.151, 1.047, 0]} rotation={[0, 0, -Math.PI / 2]} />
      <mesh geometry={nodes.Plane002.geometry} material={materials['Material.004']} position={[0.347, 1.048, 0.537]} rotation={[0, 0, Math.PI / 2]} scale={[-1, -1, -0.294]} />
      <mesh geometry={nodes.Plane006.geometry} material={materials['Material.004']} position={[1.114, 1.028, 0.47]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]} scale={-1} />
      <mesh geometry={nodes.Cylinder001.geometry} material={materials['Material.004']} position={[0.449, 1.01, 0.591]} rotation={[0, 0, -Math.PI]} scale={[-0.194, -0.095, -0.153]} />
      <mesh geometry={nodes.Plane007.geometry} material={materials['Material.001']} position={[-0.399, 1.039, 0.571]} rotation={[-Math.PI / 2, 0, 0]} scale={-0.951} />
      <mesh geometry={nodes.Plane008.geometry} material={materials['Material.001']} position={[1.113, 1.039, 0.587]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={-0.951} />
      <mesh geometry={nodes.Plane009.geometry} material={materials['Material.004']} position={[0.151, 1.047, 1.434]} rotation={[0, 0, Math.PI / 2]} scale={-1} />
    </group>
  )
}

useGLTF.preload('/assets/models/fittings/ding1_Kopie_open_mirror.glb')
