import {useContext} from 'react'
import {Icon} from '../../icon'
import {OptionsContext} from '../../../App.js'
import './showOptionPanel.css'

export default function ShowOptionPanel() {

    const { showOptionPanel, setShowOptionPanel } = useContext( OptionsContext )

    function handlerOnClick(e) {
        setShowOptionPanel(value => !value)
    }

    return (
        <button type="button" className={ showOptionPanel ? 'show_options_button' : 'show_options_button closed' } onClick={ handlerOnClick }>
            <Icon icon="arrow_right" className="show_options_button__icon arrow_icon" />
            <Icon icon="close" className="show_options_button__icon close_icon" />
        </button>
    )
}