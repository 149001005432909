import {useState, createContext, useEffect, useRef, useMemo} from 'react'
import {getPriceTable, getOptionsTables, getOptions} from './helpers/data.js'
import {getTabs, getDefaultConfig} from './helpers/functions.js'
import {configName} from './helpers/constants.js'
import Configurator from './Configurator.js'
import './app.css'

export const OptionsContext = createContext(null)
const image = new Image()

export default function App() {

    const tooltipRef = useRef()
    const [optionsTables, setOptionsTables] = useState(null)
    const [priceTable, setPriceTable] = useState(null)
    const [options, setOptions] = useState(null)
    const [tabs, setTabs] = useState(null)

    const config = {}
    const [size, setSize] = useState()

    const [defaultConfig, setDefaultConfig] = useState(null)
    const [totalPrice, setTotalPrice] = useState(0.00)

    const [showOptionPanel, setShowOptionPanel] = useState(true)
    const ref = {
        canvasWrapper: useRef(),
        venus: useRef(),
        athen: useRef(),
        kristall: useRef(),
        morena: useRef(),
        screenSaver: useRef(),
        info3d: useRef(),
        bench: { standard: {}, prestige: {}, exclusive: {}, lux: {}, comfort: {} }
    }

    /** set Price Table, Options Table */
    useEffect(() => {
        getPriceTable().then(results => {
            setPriceTable(results)
        })
        getOptionsTables().then(results => {
            setOptionsTables(results)
        })
    }, [])

    /** set Options, Default Options, Price typeWood group */
    useEffect(() => {
        const options = getOptions(optionsTables)

        if (options) {
            setOptions(options)
            setTabs(getTabs(optionsTables))
        }

    }, [optionsTables])

    const everyTrue = [options, priceTable, optionsTables, tabs].every(e => e)

    if (everyTrue) {
        return (
            <OptionsContext.Provider value={{
                tooltipRef,
                optionsTables, priceTable,
                defaultConfig, setDefaultConfig,
                config,
                size, setSize,
                tabs, setTabs,
                totalPrice, setTotalPrice,
                showOptionPanel, setShowOptionPanel,
                ref,
            }}>
                <Configurator options={options}/>
            </OptionsContext.Provider>
        )
    } else {
        return null
    }
}