import {configName, modelHeight, typeWoodNamesForPrice} from './constants.js'
import * as THREE from 'three'
import {MirroredRepeatWrapping, RepeatWrapping} from 'three'

export function getVisibleOptionsRecursive( optionGroup, allOptions ) {
    const visible = []

    function processOption(option, startOption) {
        if (option.parentGroup) {
            let parentOptions = allOptions
                .find(o => o.name === option.parentGroup)?.optionsSelected
                .filter(o => option.parentNames.includes(o.name))

            for (const parentOption of parentOptions) {
                processOption(parentOption, startOption)
            }
        } else {
            visible.push(startOption)
        }
    }

    for (const option of optionGroup) {
        processOption(option, option)
    }

    return visible
}

export function getVisibleOptions( params ) {
    const { options: optionsGroups, group = null, groupName = 'model', selected = false } = params

    let optionsGroup = null

    if ( group ) {
        optionsGroup = group
    } else if ( groupName ) {
        if (optionsGroups) {
            optionsGroup = optionsGroups.find(o => o.name === groupName)
        }
    }

    if ( !optionsGroup ) return null

    const result = getVisibleOptionsRecursive( optionsGroup.options, optionsGroups )

    return selected
        ? result.find(o => optionsGroup.optionsSelected.find(o2 => o2.id === o.id))
        : result
}

export function getRendererOptionsRecursive( options, config ) {
    const rendererOptions = []

    function processOption(option, startOption) {

        if (option.parentGroup) {

            let parentOptions = config[option.parentGroup]?.value.optionsSelected
                .filter(o => option.parentNames.includes(o.name))

            for (const parentOption of parentOptions) {
                processOption(parentOption, startOption)
            }

        } else {
            rendererOptions.push(startOption)
        }
    }

    if (Array.isArray(options)) {
        for (const option of options) {
            processOption(option, option)
        }
    }

    return rendererOptions
}

export function getRendererOptions(params) {
    const { config, groupName = 'model', selected = false } = params

    let option = config[groupName]

    if ( !option ) return null

    const result = getRendererOptionsRecursive( option.value.options, config )

    return selected
        ? result.find(o => option.value.optionsSelected.find(o2 => o2.id === o.id))
        : result
}

export function getTabs( optionTables ) {
    if (!optionTables) return

    const tabs = []

    let id = 0
    let indexRowTabsGroup = optionTables.findIndex(row => row[2] === 'tabs' )
    const keyOption = optionTables[ indexRowTabsGroup ][2]

    while ( optionTables[ ++indexRowTabsGroup ][0] ) {
        const row = optionTables[ indexRowTabsGroup ],
            dir = row[6] ? '/' + row[6].trim() : '',
            ext = row[8] ? row[8].trim() : 'png',
            imgSrc = row[7]
                ? `/assets/images/options/${ keyOption }${ dir }/${ row[7].trim() }.${ ext }` : ''

        const tab = {
            id: id++,
            title: row[0],
            name: row[2],
            image: imgSrc,
            checked: [1, '1', 'default'].includes( row[1] )
        }

        tabs.push( tab )
    }

    return tabs
}

export function replaceStr( str ) {
    return str
        .trim()
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, '')
        .replaceAll(' ', '_')
        .replaceAll('-', '_')
        .replaceAll('ß', 'ss')
        .replaceAll('(', '_')
        .replaceAll(')', '')
}

export function getArrayOptionsSortTabs(config) {
    const options = Object.keys(config).map(key => config[key].value)

    const arrayOptionsSortTabs = []

    options
        .map(option => {
            const result = {...option}

            let optionsSelected = getRendererOptions({ config, groupName: option.name })
                .find(o => option.optionsSelected.find(os => os.name === o.name && os.id === o.id))

            if (optionsSelected) {
                if (option.name !== configName.size) {
                    result.optionsSelected = optionsSelected
                }
                return result
            } else {
                if (option.name === configName.size) {
                    result.options = Object.keys(result.options).map(key => result.options[key])

                    result.options[0].value = config[configName.size].value.options.width
                    result.options[1].value = config[configName.size].value.options.depth
                    return result
                }
                return undefined
            }
        })
        .filter(o => o)
        .forEach(o => {
            if (!arrayOptionsSortTabs[o.tab]) arrayOptionsSortTabs[o.tab] = []
            arrayOptionsSortTabs[o.tab].push(o)
        })

    return arrayOptionsSortTabs
}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export function getDefaultConfig(config) {

    const defaultValues = {}

    Object.keys(config).forEach(key => {
        if (config[key].value.name === configName.size) {
            defaultValues[key] = {
                width: config[configName.size].value.width,
                depth: config[configName.size].value.depth
            }
        } else {
            defaultValues[key] = {
                optionsSelected: [...config[key].value.optionsSelected]
            }
        }
    })

    return defaultValues
}

export function setRepeatTexture(texture, w, h,  wt = 1, ht = 1, mirrored = false) {
    texture.wrapS = texture.wrapT = mirrored ? MirroredRepeatWrapping : RepeatWrapping
    texture.repeat.set( w/wt, h/ht )
    texture.needsUpdate = true
    return texture
}

export function setVisibleWalls(camera, ref) {
    if (camera && ref.current?.children) {
        // зміщення кута повороту моделі відносно осі в градусах
        // displacement of the rotation angle of the model relative to the axis in degrees
        const angleOffsetModel = 90
        const offset = 15
        let angle = Math.atan2(camera.position.x, camera.position.z) * 360 / (Math.PI * 2) + angleOffsetModel
        if (angle < 0) angle = angle + 360

        ref.current.children.forEach(g => {
            switch (g.name) {
                case 'corner':
                    g.visible = angle > 90 + 20 && angle < 270 + 70
                    break
                case 'front':
                    g.visible = angle > 90 - offset && angle < 270 + offset
                    break
                case 'right':
                    g.visible = !(angle > offset && angle < 180 - offset)
                    break
                case 'left':
                    g.visible = !(angle > 180 + offset && angle < 360 - offset)
                    break
                case 'back':
                    g.visible = !(angle > 90 + offset && angle < 270 - offset)
                    break
                case 'ceiling':
                    g.visible = camera.position.y < modelHeight / 2 + .1
                    break
            }
        })

    } else {
        if (ref.current) {
            ref.current.children.forEach(g => {
                g.visible = true
            })
        }
    }
}

export function getParamWall(model, size) {
    return {
        corner: model === 'athen' ?
            {
                position: [size.model.width - size.model.positionCorner / 2 - .0283, size.model.height / 2 - .0001, size.model.depth - size.model.positionCorner / 2 - .0283],
                rotation: [0, - (Math.PI / 2 + Math.PI / 4), 0],
                subtractionPositionX: size.model.wCorner / 2,
            } : {},

        front: model === 'athen' ?
            {
                position: [size.model.wFront / 2, size.model.height / 2, size.model.depth - size.model.wallThickness / 2],
                rotation: [0, Math.PI, 0],
                subtractionPositionX: size.model.wFront / 2
            } : {
                position: [size.model.width / 2, size.model.height / 2, size.model.depth - size.model.wallThickness / 2],
                rotation: [0, Math.PI, 0],
                subtractionPositionX: size.model.width / 2
            },

        right: model === 'athen' ?
            {
                position: [size.model.width - size.model.wallThickness / 2, size.model.height / 2, size.model.wRight / 2],
                rotation: [0, -Math.PI / 2, 0],
                subtractionPositionX: size.model.wRight / 2
            } : {
                position: [size.model.width - size.model.wallThickness / 2, size.model.height / 2, size.model.depth / 2],
                rotation: [0, -Math.PI / 2, 0],
                subtractionPositionX: size.model.depth / 2
            },

        left: {
            position: [size.model.wallThickness / 2, size.model.height / 2, size.model.depth / 2],
            rotation: [0, Math.PI / 2, 0],
            subtractionPositionX: size.model.depth / 2
        },

        back: {
            position: [size.model.width / 2, size.model.height / 2, size.model.wallThickness / 2],
            rotation: [0, 0, 0],
            subtractionPositionX: size.model.width / 2
        },
    }
}
